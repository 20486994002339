<template>
	<div class="cargoFormHeader puiformheader">
		<v-row dense>
			<v-col v-show="false" class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.portcallnumber')" :value="getServerData"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.portcallnumber')" :value="getVIsitIdValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.port')" :value="getPortNameValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.ship')" :value="getShipNameValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.portcallstatus')" :value="getPortCallStatusValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.unloadingstatus')" :value="getUnloadingstatusValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.cargo.loadingstatus')" :value="getLoadingstatusValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'cargo-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'cargo',
			dataModel: null
		};
	},
	computed: {
		getUnloadingstatusValue() {
			return this.model && this.model.unloadingstatus ? this.model.unloadingstatus : '-';
		},
		getLoadingstatusValue() {
			return this.model && this.model.loadingstatus ? this.model.loadingstatus : '-';
		},
		getShipNameValue() {
			return this.dataModel && this.dataModel.shipname != null ? this.dataModel.shipname : '-';
		},
		getPortNameValue() {
			return this.dataModel && this.dataModel.pcportdesc != null ? this.dataModel.pcportdesc : '-';
		},
		getPortCallStatusValue() {
			return this.dataModel && this.dataModel.pcstatusdesc != null ? this.dataModel.pcstatusdesc : '-';
		},
		getVIsitIdValue() {
			return this.dataModel && this.dataModel.visitid != null ? this.dataModel.visitid : '-';
		},
		getServerData() {
			const opts = {
				model: 'portcallclearancesstatus',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'portcallid',
							op: 'eq',
							data: this.model && this.model.portcallid ? this.model.portcallid : '-'
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				this.dataModel = response.data.data[0];
			});

			return 0;
		}
	}
};
</script>
